<!-- eslint-disable vue/v-on-event-hyphenation -->
<template>
  <div class="item-content col-form-newsletter">
    <div class="pre-footer-newsletter-form__title">
      {{
        $t(
          'I am interested in receiving news and other offers related to fashion for'
        )
      }}
    </div>
    <!-- eslint-disable-next-line vue/max-attributes-per-line -->
    <ValidationObserver v-slot="{ invalid }">
      <form class="pre-footer-newsletter-form" @submit.prevent>
        <div class="pre-footer-newsletter-form__container">
          <div class="pre-footer-newsletter-form__container__radio">
            <CustomRadio
              :label="$t('Woman')"
              value="f"
              name="gender-selection"
              element-id="female"
              @radioChange="radioChange"
              :selected="genderSelected"
            />
            <CustomRadio
              :label="$t('Man')"
              value="m"
              name="gender-selection"
              element-id="male"
              @radioChange="radioChange"
              :selected="genderSelected"
            />
          </div>
          <div class="pre-footer-newsletter-form__container__input">
            <CustomInput
              input-name="pre-footer-newsletter-email"
              input-id="pre-footer-newsletter-email"
              input-type="email"
              validate-rule="required|email"
              :input-placeholder="$t('Insert here your email')"
              @inputChange="inputChange"
              class="pre-footer-email-input"
            />
            <CustomButton
              :text="$t('Subscribe')"
              :disabled="!isFormValidated || invalid"
              class="pre-footer-email-button"
              @click="subscribeUser"
            />
          </div>
          <div>
            <CustomCheckbox
              @checkboxChange="handlePrivacyChange"
              label="I have read and agree to the"
            />
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import {
  defineComponent,
  ref,
  computed,
  onMounted,
} from '@vue/composition-api';
import {
  CustomButton,
  CustomCheckbox,
  CustomRadio,
  CustomInput,
} from '~/components/General';
import { useNewsletter, useUser } from '@gemini-vsf/composables';
import { useUiNotification } from '~/composables';
import { useI18n } from '~/helpers/hooks/usei18n';
import { ValidationObserver } from 'vee-validate';
import { useRecaptcha } from '~/composables/useRecaptcha';

export default defineComponent({
  name: 'PreFooterNewsletterForm',
  components: {
    CustomInput,
    CustomButton,
    CustomCheckbox,
    CustomRadio,
    ValidationObserver,
  },
  setup() {
    const genderSelected = ref('');
    const privacyAccepted = ref(false);
    const {
      subscribeEmailToNewsletterStatus,
      updateSubscription: newsletterSubscribe,
      loading: newsletterLoading,
      error: newsletterError,
    } = useNewsletter();
    const { load } = useUser();
    const { isEnabled: isRecaptchaEnabled, $recaptcha } = useRecaptcha();
    const { send: sendNotification } = useUiNotification();
    const trans = useI18n();
    const email = ref();

    const isFormValidated = computed(
      () =>
        privacyAccepted.value &&
        genderSelected.value &&
        !newsletterLoading.value
    );
    const handlePrivacyChange = (e) => {
      privacyAccepted.value = e;
    };

    const radioChange = (e) => {
      genderSelected.value = e;
    };

    const inputChange = (e) => {
      email.value = e;
    };

    const subscribeUser = async () => {
      await load();
      if (isRecaptchaEnabled.value) {
        $recaptcha.init();
      }
      if (isRecaptchaEnabled.value) {
        const recaptchaToken = await $recaptcha.execute('newsletter');

        await newsletterSubscribe({
          email: email.value,
          name: '',
          lastName: '',
          gender: genderSelected.value?.toUpperCase() ?? 'O',
          recaptchaToken,
        });
      } else {
        await newsletterSubscribe({
          email: email.value,
          name: '',
          lastName: '',
          gender: genderSelected.value?.toUpperCase() ?? 'O',
        });
      }

      if (subscribeEmailToNewsletterStatus.value === undefined) {
        sendNotification({
          id: Symbol('subscribe_error'),
          message: `${trans.t(
            'You are already subscribed to the newsletter. for support contact us at support@biffi.com!'
          )}`,
          type: 'danger',
          icon: 'error',
          persist: false,
          title: 'Subscription Error',
        });
        return;
      }
      if (
        newsletterError?.value?.updateSubscription ||
        subscribeEmailToNewsletterStatus.value !== 'SUBSCRIBED'
      ) {
        sendNotification({
          id: Symbol('subscribe_error'),
          message: `${trans.t(
            "Sorry, we couldn't subscribe you to our newsletter. Please contact us at support@biffi.com!"
          )}`,
          type: 'danger',
          icon: 'error',
          persist: false,
          title: 'Subscription Error',
        });
        return;
      }
      sendNotification({
        id: Symbol('subscribe_success'),
        message: `${trans.t('Your subscription was successful!')}`,
        type: 'success',
        icon: 'check',
        persist: false,
        title: 'Subscription Success',
      });

      if (window?.location?.href?.includes('archivesalebergamo')) {
        const deviceType = /iPad/.test(navigator.userAgent)
          ? 't'
          : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
              navigator.userAgent
            )
          ? 'm'
          : 'd';

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = `
          window.criteo_q = window.criteo_q || [];
          window.criteo_q.push(
            { event: 'setAccount', account: 108890 },
            { event: 'setEmail', email: '${email.value}' },
            { event: 'setSiteType', type: '${deviceType}' },
            { event: 'viewItem', item: '1' }
          );
        `;
        document.head.append(script);
      }
    };

    onMounted(() => {
      if (window?.location?.href?.includes('archivesalebergamo')) {
        const deviceType = /iPad/.test(navigator.userAgent)
          ? 't'
          : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
              navigator.userAgent
            )
          ? 'm'
          : 'd';
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.innerHTML = `
            window.criteo_q = window.criteo_q || [];
            window.criteo_q.push(
              { event: 'setAccount', account: 108890 },
              { event: 'setEmail', email: '' },
              { event: 'setSiteType', type: '${deviceType}' },
              { event: 'viewItem', item: '1' }
            );
          `;
        document.head.append(script);
      }
    });

    return {
      privacyAccepted,
      handlePrivacyChange,
      genderSelected,
      radioChange,
      isFormValidated,
      subscribeUser,
      inputChange,
    };
  },
});
</script>

<style lang="scss">
.col-form-newsletter {
  .subtitle {
    font-size: var(--font-size--3xs);
    line-height: var(--font-size--xs);
    color: var(--c-grey-3);
    font-family: var(--font-family--secondary);
    margin-bottom: 20px;
  }

  .pre-footer-newsletter-form {
    display: flex;
    gap: 30px;
    &__container {
      grid-gap: 1.25rem;
      gap: 1.25rem;
      display: grid;
      width: 100%;
      &__radio {
        @include font-13x16;
        display: flex;
        column-gap: 1.1rem;
      }
      &__input {
        display: grid;
        row-gap: 0.5em;
        .custom-input-without-label {
          width: 100%;
          height: unset;
          .custom-input-input {
            width: 100%;
          }
        }
      }
      .custom-checkbox > label {
        gap: 0.5rem;
      }

      .custom-checkbox__label,
      .sf-link {
        @include font-12x16;
        font-weight: 400px;
      }
    }

    &__title {
      color: var(--c-black);
      @include font-14x19;
      margin-bottom: 0.5rem;
    }

    .sf-radio__label {
      @include font-13x16;
      font-weight: 300;
    }
    .sf-input__label,
    .sf-checkbox__label {
      font-size: 17px;
    }
    .sf-checkbox__checkmark {
      --checkbox-border-color: #000000;
      outline: none !important;
      transition: none;
      .sf-icon {
        background-color: white;
        --icon-color: #000000;
      }
    }
  }
}

@include from-landscape-min {
  .col-form-newsletter {
    width: 50%;
    .pre-footer-newsletter-form {
      &__container {
        grid-gap: unset;
        gap: unset;
        &__radio {
          margin-bottom: 0.5rem;
        }
        &__input {
          display: flex;
          margin-bottom: 0.5rem;
          grid-column-gap: 1.25rem;
          column-gap: 1.25rem;
          .pre-footer-email-input {
            width: 59%;
            height: 100%;
          }
          .pre-footer-email-button {
            width: 32%;
            height: 3.125rem;
          }
        }
      }
    }
  }
}
</style>
