<template>
  <div
    class="product-card__sizes"
    v-if="Array.isArray(sizes.values) && sizes.values.length > 0"
  >
    <span
      v-for="(size, index) in sortedSizeOption"
      :key="index"
      class="product-card__sizes__size"
      :class="{ 'size-oos': size.stock <= 0 }"
      @click="handleSizeClick(size.uid, size.label)"
    >
      {{ size.label === 'TU' ? $t('One Size') : size.label }}
    </span>
  </div>
</template>

<script>
import { defineComponent, computed } from '@nuxtjs/composition-api';
import { useProduct, useCart, useUser } from '@gemini-vsf/composables';
import { useUiNotification, useKlaviyo, useGtm } from '~/composables';
import { useI18n } from '~/helpers/hooks/usei18n';
import useAtelier from '~/composables/useAtelier/useAtelier';
import { InternationalSizeMapping } from '~/assets/InternationalSizeMapping';

export default defineComponent({
  name: 'ProductCardSizes',
  props: {
    sizes: {
      type: Object,
      default: () => {},
    },
    productUid: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { search, products } = useProduct();
    const { addItem, cart, load: loadCart } = useCart();
    const { isAuthenticated } = useUser();
    const { send: sendNotification } = useUiNotification();
    const trans = useI18n();
    const { atelierStock, getStock } = useAtelier();
    const { klaviyoAddToCartPush } = useKlaviyo();
    const { actionCartGtmPush } = useGtm();

    const addToCartFail = (productName, productSize) => {
      sendNotification({
        id: Symbol('Atelier OOS'),
        message: `${productName} ${trans.t('size')} ${productSize} ${trans.t(
          'is not available'
        )}`,
        type: 'danger',
        icon: 'error',
        persist: false,
        title: 'Error',
      });
    };

    const handleSizeClick = async (sizeUid, sizeLabel) => {
      const baseSearchQuery = {
        filter: {
          uid: props.productUid,
        },
        configurations: [sizeUid],
      };
      await search({
        queryType: 'DETAIL',
        customQuery: {
          productDetail: 'productDetailCustom',
        },
        ...baseSearchQuery,
      });
      const productResult = products.value?.items[0];
      let stock =
        productResult?.configurable_product_options_selection?.variant
          ?.only_x_left_in_stock ?? 0;
      if (stock > 0) {
        await getStock(
          productResult.atelier_id,
          productResult.configurable_options
            .find((e) => e.label === 'Size' || e.label === 'Taglia')
            .values.find((v) => v.uid === sizeUid).label
        );
        stock = atelierStock.value
          ? atelierStock.value < stock
            ? atelierStock.value
            : stock
          : 0;
      }
      if (stock > 0) {
        if (!cart.value) {
          await loadCart({
            customQuery: !isAuthenticated.value
              ? {
                  cart: 'cartCustom',
                }
              : {
                  customerCart: 'customerCartCustom',
                },
          });
        }
        const productQtyInCart =
          cart.value?.items?.find((item) => {
            return (
              item?.product?.sku ===
              productResult?.configurable_product_options_selection?.variant
                ?.sku
            );
          })?.quantity || 0;
        stock -= productQtyInCart;
        if (stock > 0 && productResult?.max_sal_qty !== null) {
          let salableStock = productResult.max_sal_qty;
          if (salableStock > 0) {
            cart.value?.items?.forEach((item) => {
              salableStock -=
                productResult.uid === item.product.uid ? item.quantity : 0;
            });
          }
          stock = salableStock < stock ? salableStock : stock;
        }
      }
      if (stock < 1) {
        addToCartFail(productResult?.name, sizeLabel);
      } else {
        try {
          const product = products.value?.items[0];
          if (product)
            await addItem({
              product,
              quantity: 1,
              customQuery: {
                addConfigurableProductsToCart:
                  'addConfigurableProductsToCartCustom',
              },
            });
          klaviyoAddToCartPush(product, 1);
          actionCartGtmPush(product, 1, 'add_to_cart', 0, 0, true);
          emit('addToCart');
          sendNotification({
            id: Symbol('add_to_cart_success'),
            message: `${productResult.name} ${trans.t(
              'was added to your cart'
            )}`,
            type: 'success',
            persist: false,
            icon: 'check',
          });
        } catch {
          addToCartFail(productResult?.name, sizeLabel);
        }
      }
    };

    const mapOrder = (array, order, key) => {
      array.sort((a, b) => {
        const A = a[key];
        const B = b[key];
        return order.indexOf(A) > order.indexOf(B) ? 1 : -1;
      });
      return array;
    };

    const sortedSizeOption = computed(() => {
      let optionSize = props.sizes.values;

      let checkSize = false;
      if (optionSize && props.sizes.attribute_code === 'size') {
        optionSize.map((value) => {
          if (!checkSize) {
            InternationalSizeMapping.map((size) => {
              if (!checkSize && value.label === size) {
                optionSize = mapOrder(
                  optionSize,
                  InternationalSizeMapping,
                  'label'
                );
                checkSize = true;
              }
              return true;
            });
          }
          return true;
        });
      }
      return optionSize;
    });

    return {
      handleSizeClick,
      getStock,
      sortedSizeOption,
    };
  },
});
</script>

<style lang="scss" scoped>
.product-card {
  &__sizes.product-card__menu__sizes {
    flex-wrap: wrap;
    row-gap: 0.0625rem;
    @include from-portrait-min {
      padding-right: 0.625rem;
    }
  }
}

.product-card__sizes {
  &__size {
    @include font-13x16;
    font-weight: var(--font-weight-light);
    @include pointer;
    &.size-oos {
      color: var(--c-grey);
      text-decoration: line-through;
    }
  }
}
</style>
