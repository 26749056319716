import { render, staticRenderFns } from "./ProductCardImage.vue?vue&type=template&id=2bd0192f&scoped=true&"
import script from "./ProductCardImage.vue?vue&type=script&lang=js&"
export * from "./ProductCardImage.vue?vue&type=script&lang=js&"
import style0 from "./ProductCardImage.vue?vue&type=style&index=0&id=2bd0192f&prod&lang=scss&scoped=true&"
import style1 from "./ProductCardImage.vue?vue&type=style&index=1&id=2bd0192f&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bd0192f",
  null
  
)

export default component.exports