<template>
  <div :class="wishlistClass" v-show="showWishlistIcon || isPdp">
    <HeartEmptyIcon @click="$emit('addToWishlist')" v-if="!isInWishlist" />
    <HeartFullIcon @click="$emit('addToWishlist')" v-else />
  </div>
</template>

<script>
import { defineComponent, computed } from '@nuxtjs/composition-api';
import { HeartFullIcon, HeartEmptyIcon } from '~/components/General/Icons';

export default defineComponent({
  name: 'ProductCardWishlist',
  components: { HeartFullIcon, HeartEmptyIcon },
  props: {
    wishlistClass: {
      type: String,
      default: 'product-card__wishlist',
    },
    hoverOnCard: {
      type: Boolean,
      default: false,
    },
    showWishlist: {
      type: Boolean,
      default: false,
    },
    isPdp: {
      type: Boolean,
      default: false,
    },
    isInWishlist: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const showWishlistIcon = computed(
      () => props.isInWishlist || props.hoverOnCard || props.showWishlist
    );

    return {
      showWishlistIcon,
    };
  },
});
</script>

<style lang="scss" scoped>
@include to-mobile-max {
  .product-card__wishlist {
    grid-area: wishlist;
    @include flex-v-center;
    justify-content: flex-end;
  }
}
.product-card__wishlist {
  @include flex-h-center;
  @include pointer;
}
</style>
