<template>
  <div class="pre-footer-newsletter__container" :class="`${layout}`">
    <div v-if="preFooterContent" class="pre-footer-newsletter__text">
      <div class="pre-footer-newsletter__text__title">
        {{ preFooterContent.title }}
      </div>
      <div class="pre-footer-newsletter__text__subtitle">
        {{ preFooterContent.description }}
      </div>
    </div>
    <div v-else class="pre-footer-newsletter__text" />
    <PreFooterNewsletterForm />
  </div>
</template>

<script>
import { defineComponent, ref, useFetch } from '@nuxtjs/composition-api';
import { useI18n } from '~/helpers/hooks/usei18n';
import { contentGetters, useContent } from '@gemini-vsf/composables';
import PreFooterNewsletterForm from './PreFooterNewsletterForm.vue';

export default defineComponent({
  name: 'PreFooterNewsletter',
  components: { PreFooterNewsletterForm },
  props: {
    layout: {
      type: String,
      default: '',
    },
  },
  setup() {
    const { locale } = useI18n();
    const { blocks, loadBlocks } = useContent(
      `prefooterNewsletter-${locale.includes('it') ? 'it' : 'en'}`
    );
    const preFooterSelector = `prefooter-newsletter-${
      locale.includes('it') ? 'it' : 'en'
    }`;
    const preFooterContent = ref();

    useFetch(async () => {
      await loadBlocks({ identifiers: [preFooterSelector] });
      const blockData = await contentGetters.getCmsBlockContent(blocks.value);
      try {
        preFooterContent.value = blockData[preFooterSelector].data;
      } catch {
        console.warn('Unable to fetch block content.');
      }
    });

    return {
      preFooterContent,
    };
  },
});
</script>

<style lang="scss" scoped>
.pre-footer-newsletter {
  &__container {
    color: var(--c-black);
    margin: 0 1.563rem;
    display: flex;
    justify-content: space-between;
  }
  &__text {
    gap: 0.625rem;
    margin-bottom: 1.5rem;
    display: grid;
    &__title {
      @include font-30x36;
      text-transform: uppercase;
    }
    &__subtitle {
      @include font-14x19;
    }
  }
}
@media (max-width: 1023px) {
  .pre-footer-newsletter {
    &__container {
      flex-direction: column;
    }
  }
}
@include from-landscape-min {
  .pre-footer-newsletter {
    &__container {
      max-width: 1440px;
      margin: 0 6.125rem;
      justify-content: space-between;
      box-sizing: border-box;
    }
    &__text {
      width: 50%;
      &__title {
        width: 20.625rem;
      }
      &__subtitle {
        width: 25.25rem;
      }
    }
  }
}
.pre-footer-newsletter__container.vertical {
  flex-direction: column;
  margin: 0;
  gap: 3rem;
  .pre-footer-newsletter {
    &__text {
      width: auto;
      &__title {
        width: auto;
      }
      &__subtitle {
        width: auto;
      }
    }
  }
  ::v-deep .col-form-newsletter {
    width: auto;
  }
}
</style>
