<template>
  <SfLink class="product-card__image" :link="link">
    <nuxt-img
      v-show="!isHovering || rolloverImage === ''"
      :width="width"
      :height="height"
      :src="image ? image.replace('&f=a', '') : placeholder"
      :alt="alt || 'product_card_image_alt'"
      image-tag="nuxt-img"
      :placeholder="placeholder"
      class="card_image"
    />
    <nuxt-img
      v-if="rolloverImage && rolloverImage !== ''"
      v-show="isHovering"
      :width="width"
      :height="height"
      :src="rolloverImage ? rolloverImage.replace('&f=a', '') : placeholder"
      :alt="alt || 'product_card_image_alt'"
      image-tag="nuxt-img"
      loading="lazy"
      :placeholder="placeholder"
      class="card_image"
    />
  </SfLink>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { SfLink } from '@storefront-ui/vue';

export default defineComponent({
  name: 'ProductCardImage',
  components: { SfLink },
  props: {
    src: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 195,
    },
    height: {
      type: Number,
      default: 240,
    },
    alt: {
      type: String,
      default: 'product image',
    },
    placeholder: {
      type: String,
      default: '/icons/components/product_placeholder.svg',
    },
    loading: {
      type: Boolean,
      default: true,
    },
    image: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
    rolloverImage: {
      type: String,
      default: '',
    },
    isHovering: {
      type: Boolean,
      default: false,
    },
  },
  setup() {},
});
</script>

<style lang="scss" scoped>
.product-card__image {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>

<style lang="scss">
.product-card__image {
  @include flex-center;
  .sf-image,
  .card_image {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    &--wrapper,
    &--placeholder {
      height: 100%;
      width: 100%;
      background-color: #f1f1f1;
    }
    &--placeholder {
      padding: var(--space-s);
    }
  }
}
</style>
