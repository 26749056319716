<template>
  <div
    class="product-card__detail__price"
    :class="{ 'product-card-has-placeholder': hasPlaceholder }"
  >
    <span class="product-card__detail__price__special" v-if="hasSpecialPrice">
      {{ $fc(price.special) }}
    </span>
    <span
      class="product-card__detail__price__regular"
      :class="{
        'has-special': hasSpecialPrice,
      }"
    >
      {{ $fc(price.regular) }}
    </span>
    <span class="product-card__detail__price__discount" v-if="hasSpecialPrice">
      -{{ discountPercentage }}%
    </span>
    <!-- <span class="product-card__detail__price__extra20" v-if="hasSpecialPrice">
      - EXTRA 20%
    </span> -->
  </div>
</template>

<script>
import { defineComponent, computed } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ProductCardPrice',
  components: {},
  props: {
    price: {
      type: Object,
      required: true,
    },
    hasPlaceholder: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const hasSpecialPrice = computed(
      () => props.price.special && props.price.special > 0
    );

    const discountPercentage = Math.round(
      ((props.price.regular - props.price.special) * 100) / props.price.regular
    );

    const reversePriceAndCurrency = (s) => s.split(/\s/g).reverse().join(' ');

    return {
      hasSpecialPrice,
      reversePriceAndCurrency,
      discountPercentage,
    };
  },
});
</script>

<style lang="scss" scoped>
.product-card__detail__price {
  display: flex;
  gap: 0.313rem;
  &__regular {
    &.has-special {
      color: var(--c-grey-2);
      text-decoration: line-through;
    }
  }
  &__extra20 {
    font-weight: bold;
  }
  &__discount {
    color: #800000;
    margin-left: auto;
  }
}
@include to-mobile-max {
  .product-card__detail__price {
    margin: 0.625rem 0 1.25rem;
  }
}
@media (max-width: 380px) {
  .product-card__detail__price {
    margin-bottom: 0.625rem;
  }
}
@media (max-width: 492px) {
  .product-card__detail__price__extra20 {
    display: none;
  }
}
</style>
