<template>
  <div class="product-card__menu" :class="`product-card__menu__${position}`">
    <ProductCardSeason :season="season" class="product-card__menu__season" />
    <ProductCardWishlist
      class="product-card__menu__wishlist"
      :show-wishlist="true"
      @addToWishlist="$emit('addToWishlist')"
      :is-in-wishlist="isInWishlist"
    />
    <ProductCardSizes
      :sizes="sizes"
      class="product-card__menu__sizes"
      :product-uid="productUid"
      @addToCart="$emit('addToCart')"
    />
    <div class="product-card__menu__minus-icon" @click="handleClickMinus">
      <MinusIcon />
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { MinusIcon } from '~/components/General/Icons';
import {
  ProductCardSizes,
  ProductCardSeason,
  ProductCardWishlist,
} from '~/components/Products/ProductCardComponents';

export default defineComponent({
  name: 'ProductCardMenu',
  components: {
    MinusIcon,
    ProductCardSizes,
    ProductCardSeason,
    ProductCardWishlist,
  },
  props: {
    sizes: {
      type: Object,
      default: () => {},
    },
    season: {
      type: String,
      default: '',
    },
    position: {
      type: String,
      default: 'left',
    },
    productUid: {
      type: String,
      default: '',
    },
    isInWishlist: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const handleClickMinus = () => {
      emit('toggleMenu', false);
    };
    return { handleClickMinus };
  },
});
</script>

<style lang="scss" scoped>
.product-card__menu {
  position: absolute;
  display: grid;
  top: -1px;
  background-color: var(--c-white);
  grid-template-rows: repeat(3, 1fr);
  grid-template-areas:
    'minus minus minus'
    'season . wishlist'
    'sizes sizes sizes';
  &__minus-icon {
    grid-area: minus;
    @include flex-center;
    @include pointer;
  }
  &__sizes {
    grid-area: sizes;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.813rem;
    margin-left: 10vw;
    margin-right: 10vw;
    border-top: 1px solid var(--c-light-grey);
  }
  &__season {
    grid-area: season;
    @include flex-v-center;
    margin-left: 10vw;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
  }
  &__wishlist {
    grid-area: wishlist;
    @include flex-v-center;
    margin-right: 10vw;
    justify-content: flex-end;
  }
  &__left {
    left: 0;
    margin: 0 auto;
  }
  &__center {
    margin: 0 auto;
  }
  &__right {
    left: -50vw;
  }
}

@include to-mobile-max {
  .product-card__menu {
    height: 9rem;
    width: 100vw;
  }
}

@include from-portrait-min {
  .product-card__menu {
    max-width: 100%;
    display: flex;
    margin: 0;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    box-sizing: border-box;
    position: static;
    grid-template-areas: unset;
    &__wishlist,
    &__season {
      display: none;
    }
    &__sizes {
      width: fit-content;
      margin: 0;
      border: none;
    }
    &__minus-icon {
      width: fit-content;
      height: 100%;
    }
  }
}
</style>
