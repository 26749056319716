<template>
  <div class="product-card__plus" @click="handleClick">
    <PlusIcon />
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { PlusIcon } from '~/components/General/Icons';

export default defineComponent({
  name: 'ProductCardPlus',
  components: { PlusIcon },
  setup(_, { emit }) {
    const handleClick = () => {
      emit('toggleMenu', true);
    };
    return {
      handleClick,
    };
  },
});
</script>

<style lang="scss" scoped>
.product-card__plus {
  @include flex-center;
}
</style>
