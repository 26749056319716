<template>
  <SfLink class="product-card__detail" :link="link">
    <h2 class="product-card__detail__brand">
      {{ brand }}
    </h2>

    <h3 class="product-card__detail__name">
      {{ name }}
    </h3>
    <ProductCardPrice :price="price" :has-placeholder="price.placeholder" />
  </SfLink>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import ProductCardPrice from '~/components/Products/ProductCardComponents/ProductCardPrice.vue';
import { SfLink } from '@storefront-ui/vue';

export default defineComponent({
  name: 'ProductCardDetail',
  components: { ProductCardPrice, SfLink },
  props: {
    brand: {
      type: String,
      default: 'Loading brand...',
    },
    name: {
      type: String,
      default: 'Loading product name...',
    },
    price: {
      type: Object,
      default: () => {
        return { regular: 9999.9, special: 0.01, placeholder: true };
      },
    },
    link: {
      type: String,
      default: '',
    },
  },
});
</script>

<style lang="scss" scoped>
.product-card__detail {
  text-decoration: unset;
  background-color: var(--c-white);
  @include font-13x16;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1rem;
  gap: 0;
  &__brand,
  &__name {
    @include ellipsis;
  }
  &__brand {
    text-transform: uppercase;
    @include font-13x16;
    font-weight: var(--font-weight-medium);
  }
  &__name {
    @include font-13x16;
    font-weight: var(--font-weight-light);
  }
}

@include to-mobile-max {
  .product-card__detail {
    line-height: 0.813rem;
  }
}

@include from-portrait-min {
  .product-card__detail {
    padding: 0.313rem 1rem;
    gap: 0.5rem;
    justify-content: center;
  }
}

@include from-landscape-min {
  .product-card__detail {
    padding: 1rem;
    gap: 0.25rem;
    justify-content: center;
    &__brand {
      font-weight: var(--font-weight-semibold);
    }
  }
}
</style>
