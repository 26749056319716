<template>
  <div
    class="product-card"
    @mouseenter="hoverCard(true)"
    @mouseleave="hoverCard(false)"
    v-if="showProductCard"
  >
    <ProductCardImage
      :image="productData.productImage"
      :rollover-image="productData.rolloverImage"
      :is-hovering="isHovering"
      :link="$fixUrlForCurrentLocale(productData.productUrl)"
      :alt="`${productData.details.brand} - ${productData.details.productName}`"
    />
    <ProductCardDetail
      :name="productData.details.productName"
      :price="productData.details.productPrice"
      :brand="productData.details.brand"
      :link="$fixUrlForCurrentLocale(productData.productUrl)"
    />
    <ProductCardRuler @toggleMenu="toggleMenu" v-show="isHovering" />
    <ProductCardWishlist
      :hover-on-card="isHovering"
      :is-in-wishlist="isInWishlist"
      @addToWishlist="$emit('addToWishlist')"
    />
    <ProductCardSeason
      :season="productData.productSeason"
      :show-star="showStar"
    />
    <ProductCardPlus @toggleMenu="toggleMenu" v-if="!showMenu" />
    <ProductCardMenu
      v-if="showMenu"
      :season="productData.productSeason"
      :sizes="productData.productSizes[0]"
      :class="{ 'show-menu': showMenu }"
      @toggleMenu="toggleMenu"
      :position="position % 2 === 0 ? 'left' : 'right'"
      :product-uid="productData.productUid"
      @addToCart="$emit('addToCart')"
      @addToWishlist="$emit('addToWishlist')"
      :is-in-wishlist="isInWishlist"
    />
  </div>
  <SfSkeleton class="product-card__skeleton" v-else />
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
  useContext,
} from '@nuxtjs/composition-api';
import {
  ProductCardDetail,
  ProductCardImage,
  ProductCardRuler,
  ProductCardWishlist,
  ProductCardSeason,
  ProductCardPlus,
  ProductCardMenu,
} from '~/components/Products/ProductCardComponents';
import { useProductPromotions } from '~/composables';
import { productGetters } from '@gemini-vsf/composables';
import { SfSkeleton } from '@storefront-ui/vue';
import { validateTimeInterval } from '~/helpers/dateHelper';

export default defineComponent({
  name: 'ProductCard',
  components: {
    ProductCardDetail,
    ProductCardImage,
    ProductCardRuler,
    ProductCardWishlist,
    ProductCardSeason,
    ProductCardPlus,
    ProductCardMenu,
    SfSkeleton,
  },
  props: {
    position: {
      type: Number,
      default: 0,
    },
    product: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: true,
    },
    season: {
      type: String,
      default: 'FW22',
    },
    isInWishlist: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { product, loading } = props;
    const showMenu = ref(false);
    const isHovering = ref(false);
    const { app, $vsf } = useContext();
    const {
      seasonsToDisplay,
      starSeasons,
      starBrands,
      starSkus,
      starPeriodoCambio,
      // starCategories,
      starStartDate,
      starEndDate,
    } = $vsf.$gemini.config;
    const seasonLabels = computed(() => seasonsToDisplay?.split(',') ?? []);
    const showProductCard = computed(
      () => !loading && product && Object.keys(product).length > 0
    );

    const toggleMenu = (e) => {
      isHovering.value = false;
      showMenu.value = e;
    };

    const hoverCard = (show) => {
      isHovering.value = show;
    };

    const productData = computed(() => {
      return {
        rolloverImage:
          productGetters.getRolloverImage(product, 359) ||
          productGetters.getProductThumbnailImage(product, 359) ||
          '',
        productImage:
          productGetters.getProductThumbnailImage(product, 359) || '',
        details: {
          productName: productGetters.getName(product) || undefined,
          productPrice: {
            regular:
              (product?.price_range &&
                productGetters.getPrice(product).regular) ||
              9999.99,
            special:
              (product?.price_range &&
                productGetters.getPrice(product).special) ||
              null,
            placeholder: false,
          },
          brand: product.brand_attribute?.label ?? '',
          gender: product.gender_attribute?.label ?? '',
        },
        productUrl: app.$productUrl(product),
        productSizes: product.configurable_options || [],
        productUid: productGetters.getEntityId(product),
        productSeason:
          seasonLabels.value.includes(product.season_code_attribute?.label) ||
          seasonLabels.value.length === 0
            ? product.season_code_attribute?.label
            : '',
        periodoCambio: product.periodo_cambio || '',
        categories: product.categories || [],
        sku: product.merchant_sku || '',
      };
    });

    const { productHasPromoStar } = useProductPromotions();
    const skusWithoutStar = new Set([
      '2403391M662',
      '2403392M001',
      '2403392M651',
      '2407187001',
      '2407189001',
      '2407190001',
      '2407190663',
      '2407191001',
      '2407191651',
      '240725S001',
      '240725S101',
      '240726S001',
      '240726S101',
      '240728S001',
      '240728S651',
      '1026371MOSS',
      '1026372BLACK',
      '1026375CARAFE',
      '1026379MOSS',
      '1026596BLACK',
      '1026597MOSS',
      '1026615KURKUMA',
      '1026630CARAFE',
      '1026701BLACK',
    ]);
    const isCustomCondition = computed(
      () =>
        productData.value?.productSeason === 'SS24' &&
        (product?.brand_attribute?.value ===
          '7ffa94fd-6060-459f-94a1-9ed4d4ef7cba' || // Adidas Originals
          product?.brand_attribute?.value ===
            '5a1703e9-ce20-4698-add2-96f3e2a672bd' || // Tod's
          product?.brand_attribute?.value ===
            '7dd1c315-29aa-4b8a-94cb-0aae109b3c3b' || // Hogan
          product?.brand_attribute?.value ===
            '7f7a4354-cb93-405f-8bf8-a3698cb0a098' || // UGG
          product?.brand_attribute?.value ===
            '8f9547e2-dcab-4435-8b91-574b2fa600cb' || // Bottega Veneta
          product?.brand_attribute?.value ===
            '7255177b-6c53-4380-b457-848488726a13' || // Stone Island
          skusWithoutStar.has(productData.value?.sku))
    );
    // const productHasNotStarCategories = computed(
    //   () =>
    //     productData.value?.categories?.length > 0 &&
    //     starCategories?.length > 0 &&
    //     !starCategories.find((sC) =>
    //       productData.value.categories.find((pC) => sC === pC.uid)
    //     )
    // );
    // const productHasNotSpecialPrice = computed(
    //   () =>
    //     productData.value?.details?.productPrice?.regular &&
    //     (!productData.value.details.productPrice.special ||
    //       productData.value.details.productPrice.regular ===
    //         productData.value.details.productPrice.special)
    // );
    const starHasValidDate = ref(
      validateTimeInterval(starStartDate, starEndDate)
    );
    const showStar = computed(
      () =>
        (!!(
          (productData.value?.sku &&
            starSkus?.length > 0 &&
            starSkus.includes(productData.value.sku)) ||
          (productData.value?.productSeason &&
            starSeasons?.length > 0 &&
            starSeasons.includes(productData.value.productSeason)) ||
          (product?.brand_attribute?.value &&
            starBrands?.length > 0 &&
            starBrands.includes(product.brand_attribute.value)) ||
          (productData.value?.periodoCambio &&
            starPeriodoCambio?.length > 0 &&
            starPeriodoCambio.includes(productData.value.periodoCambio)) ||
          isCustomCondition.value
        ) &&
          starHasValidDate.value) ||
        productHasPromoStar(product)
    );

    return {
      showMenu,
      toggleMenu,
      hoverCard,
      isHovering,
      productData,
      showProductCard,
      showStar,
    };
  },
});
</script>

<style lang="scss" scoped>
.product-card {
  // 195x319 mobile 390
  // 341x521 desktop 1440
  width: 100%;
  aspect-ratio: 1 / 1.53;
  display: grid;
  flex: 1;
  grid-template-columns: repeat(10, 1fr);
  grid-template-rows: repeat(17, 1fr);
  position: relative;
  border-right: var(--grey-border);
  border-bottom: var(--grey-border);

  &:hover {
    .product-card__season {
      background-color: rgba(220, 220, 220, 0.65);
      width: fit-content;
      padding: 0 0.1875rem;
      border-radius: 0.1875rem;
    }
  }

  &__skeleton {
    width: 100%;
    padding-bottom: 153%;
  }
  &__image {
    grid-column: 1 / span 10;
    grid-row: 1 / span 13;
  }

  &__detail {
    grid-column: 1 / span 10;
    grid-row: 14 / span 17;
    width: 100%;
  }

  &__plus {
    grid-column: 9 / span 10;
    grid-row: 1 / 3;
    z-index: 1;
  }

  &__ruler {
    display: none;
  }

  &__menu {
    &.show-menu {
      z-index: 2;
      @include to-portrait-max {
        z-index: 99999999;
      }
    }
  }
}

@include to-mobile-max {
  .product-card {
    &__wishlist {
      display: none;
    }

    &__menu {
      &.show-menu {
        position: fixed;
        top: auto;
        bottom: 0;
        left: 0;
      }
    }

    &__season {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.125rem;
      grid-column: 2 / 3;
      grid-row: 1 / 3;
      z-index: 1;
    }
  }
}

@include from-portrait-min {
  .product-card {
    &__plus {
      display: none;
    }

    &__ruler {
      display: block;
      grid-row: 15;
      grid-column: 10;
      z-index: 1;
    }

    &__image {
      grid-column: 1 / span 14;
    }

    &__wishlist {
      padding-left: 0.938rem;
      grid-row: 15;
      grid-column: 1;
      z-index: 1;
    }

    &__season {
      display: flex;
      grid-row: 1;
      grid-column: 1 / span 4;
      z-index: 1;
      height: fit-content;
      width: fit-content;
    }

    &__menu {
      grid-column: 1 / span 10;
      grid-row: 14 / 16;
    }

    &__image {
      grid-row: 1 / span 15;
    }

    &__detail {
      grid-row: 16 / span 17;
    }
  }

  @include from-landscape-min {
    .product-card {
      &__menu {
        grid-column: 1 / span 10;
        grid-row: 12 / 14;
      }
      &__image {
        grid-row: 1 / span 13;
      }

      &__wishlist,
      &__ruler {
        grid-row: 13;
      }

      &__detail {
        grid-row: 14 / span 17;
      }
    }
  }

  @include from-desktop-min {
    .product-card {
      &__menu {
        grid-column: 1 / span 10;
        grid-row: 13 / 15;
      }
      &__image {
        grid-row: 1 / span 14;
      }

      &__wishlist,
      &__ruler {
        grid-row: 14;
      }

      &__detail {
        grid-row: 15 / span 17;
      }
    }
  }
}
</style>
