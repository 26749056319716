<template>
  <div class="product-card__season" v-show="season || showStar">
    <StarIcon v-if="showStar" />
    {{ season }}
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { StarIcon } from '~/components/General/Icons';

export default defineComponent({
  name: 'ProductCardSeason',
  components: { StarIcon },
  props: {
    season: {
      type: String,
      default: '',
    },
    showStar: {
      type: Boolean,
      default: false,
    },
  },
  setup() {},
});
</script>

<style lang="scss" scoped>
.product-card__season {
  @include font-13x16;
  font-weight: var(--font-weight-light);
  @include flex-v-center;
  align-items: flex-start;
  justify-content: flex-start;
}
@include from-portrait-min {
  .product-card__season {
    gap: 0.313rem;
    position: relative;
    left: 0.938rem;
    top: 0.938rem;
  }
}
</style>
