<template>
  <div class="product-card__ruler">
    <RulerIcon @click="handleClick" width="22" height="12" />
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { RulerIcon } from '~/components/General/Icons';

export default defineComponent({
  name: 'ProductCardRuler',
  components: { RulerIcon },
  props: {},
  setup(_, { emit }) {
    const handleClick = () => {
      emit('toggleMenu', true);
    };
    return { handleClick };
  },
});
</script>

<style lang="scss" scoped>
.product-card__ruler {
  width: 22px;
  height: 12px;
  margin-right: 0.938rem;
  @include pointer;
  svg {
    width: 22;
    height: 12;
    rect {
      width: 22;
      height: 12;
    }
  }
}
</style>
